import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`REST API Overview`}</h1>
    <p>{`Learn about resources, libraries, previews and troubleshooting for enablehr's REST API.`}</p>
    <ul>
      <li parentName="ul">{`Table of Contents`}</li>
      <li parentName="ul">{`Resources in the REST API`}</li>
      <li parentName="ul">{`Media types`}</li>
      <li parentName="ul">{`Other authentication methods`}</li>
      <li parentName="ul">{`Troubleshooting`}</li>
      <li parentName="ul">{`API previews`}</li>
      <li parentName="ul">{`Libraries`}</li>
      <li parentName="ul">{`OpenAPI description`}</li>
      <li parentName="ul">{`Endpoints available for enablehr Apps`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      